
<template>
    <div>
        <v-col cols="12" sm="4">
            <h4>Editar Emergencia</h4>
        </v-col>
        <v-form>

            <v-text-field v-model="currentEmergencia.nombre" clearable type="text" label="Nombre"></v-text-field>
            <!-- <v-btn color="primary" @click="saveEmergencia();  reset, resetValidation, newEmergencia()"> -->
            <v-btn class="ml-1" width="auto" color="primary" @click="updateEmergencia(); alert = true; snackbar = true">
                Actualizar
            </v-btn>
            <v-btn color="red" @click="goToList"> Volver</v-btn>
            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>

        </v-form>

        <v-snackbar v-model="snackbar" :multi-line="multiLine">
            Emergencia guardada correctamente a las {{ showEditedTime() }}
            <template v-slot:action="{ attrs }">
                <v-btn color="green" text v-bind="attrs" @click="snackbar = false">
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>

    </div>
</template>

<script>
import EmergenciaDataService from "../services/EmergenciaDataService";
import ConfirmDialogue from './ConfirmDialogue.vue';
export default {
    name: "emergencias-edit",
    components: {
        ConfirmDialogue
    },
    data() {
        return {

            dialog: false,
            snackbar: false,
            requiredRules: [
                v => !!v || 'Este campo es requerido'
            ],
            multiLine: true,
            currentEmergencia: {
                id: null,
                nombre: ""

            },
            submitted: false,

        };

    },
    methods: {
        async goToList() {
            const ok = await this.$refs.confirmDialogue.show({
                title: 'Volver al listado?',
                message: 'Todos los cambios se perderan.',
                okButton: 'Volver',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.$router.push("/emergencias");
            } else {
                // Doing nothing now, can throw an alert() too
            }
        },
        showEditedTime() {
            var editedTime = new Date();
            editedTime = editedTime.toLocaleTimeString(navigator.language, {
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            });
            return editedTime
        },


        getEmergencia(id) {
            EmergenciaDataService.get(id)
                .then(response => {
                    this.currentEmergencia = response.data;
                    console.log(this.currentEmergencia);
                })
                .catch(e => {
                    console.log(e);
                });
        },

        updateEmergencia() {
            EmergenciaDataService.update(this.currentEmergencia.id, this.currentEmergencia)
                .then(response => {
                    console.log("updateEmergencia() Response data: " + response.data);

                })
                .catch(e => {
                    console.log(e);
                });
        },

        // THIS IS FOR THE DROPDOW LIST
        /*
        saveEmergencia() {
            var data = {
                nombre: this.emergencia.nombre

            };
            EmergenciaDataService.create(data)
                .then((response) => {
                    this.emergencia.nombre= response.data.nombre;
                    this.submitted = true;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        newEmergencia() {
            this.submitted = false;
            this.emergencia = {};
        },*/
    },
    mounted() {
        this.getEmergencia(this.$route.params.id)
    }
};
</script>

<style>
.submit-form {
    max-width: 800px;
}

.styled-input label[for] {
    height: 30px;
    font-size: 12pt;
}

/* Hide up and down buttons on numeric type fields */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
</style>
